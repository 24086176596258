.transaction {
  display: grid;
  grid-template-columns: auto 135px;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  align-items: center;
  color: #222;
  text-decoration: none;
  border-radius: .3em;
  margin: .2em -.7em;
  padding: .5em .7em .6em;
}

a.transaction:hover {
  color: #222;
  background: rgba(0,0,0,.04);
}

a.transaction:focus {
  outline: none;
  box-shadow: 0 0 0 2px #aa8941;
}

a.transaction:active {
  background: rgba(0,0,0,.08);
}

.transaction h4 {
  margin: .2em 0;
  color: #111;
}

.status {
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', system-ui, sans-serif;
  border-radius: .5em;
  background: #777;
  font-size: .7em;
  font-weight: bold;
  padding: .2em .6em;
  line-height: 1.25em;
  vertical-align: 5%;
  color: #fff;
  white-space: nowrap;
  margin: 0 .4em 0 0;
}

.status.Completed {
  background: #bca36c;
}

.status.Open, .status.CustomerSent, .status.Active {
  background: #F6C358;
} 

.status.Failed, .status.Cancelled {
  background: #ff333a;
}

.datetime, .fiatamount {
  padding-top: .3em;
  font-size: .875em;
  color: #777;
}

.fiatamount,
.productamount {
  text-align: right;
}

.positive {
  color: #093;
}

.positive::before {
  content: "+";
}

.negative {
  color: #ff333a;
}
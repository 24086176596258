.transactions {
  position: relative;
  background: #fff;
  color: #111;
}

.transactions section {
  margin: 0 auto;
  padding: 1em 1em 2em;
  max-width: calc(980px - 2em);
  box-shadow: 0 50px 100px rgba(0,0,0,.1);
  min-height: 415px;
}

.transactions h3 > a {
  float: right;
  font-size: .85em;
  font-weight: normal;
  padding: .5em;
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', system-ui, sans-serif;
  margin: -.5em;
  letter-spacing: normal;
}

.transactions h3 {
  margin: .35em auto 1.5em;
  max-width: 580px;
}

.options {
  display: flex;
  max-width: 580px;
  margin: 0 auto 2em;
  overflow-y: hidden;
  overflow-x: auto;
}

.options hr  {
  flex: 0;
  margin: 0;
  border: 0;
}

.options button {
  border: none !important;
  border-radius: .3em .3em 0 0 !important;
  border-bottom: 1px solid #ddd !important;
  box-shadow: none;
  background: none;
  padding: .5em .65em calc(.45em + 1px);
  margin: 0;
  flex: 1;
  color: #555 !important;
  transition: none;
  align-self: flex-end;
}

.options button:hover,
.options button.active,
.options button:active,
.options button:focus {
  background-color: transparent;
  border-bottom: 2px solid #ccc !important;
  color: #222 !important;
  padding-bottom: .45em;
  box-shadow: none !important;
}

.options button.active,
.options button:active {
  border-bottom-color: #bca36c !important;
}

.options button:active {
  background-color: rgba(0, 0, 0, .04);
}

.options button:focus {
  border-bottom: 0px !important;
  box-shadow: inset 0 0 0 2px #837652 !important;
  padding-bottom: calc(.45em + 2px);
}

.options button div {
  font-size: 0.875em;
  color: #777;
}

.transactions ul {
  padding: 0;
  margin: -.8em auto 0;
  max-width: 580px;
}


.transactions li {
  list-style-type: none;
  animation: fade .6s 0s ease-out 1;
  opacity: 1;
  transform: translateY(0);
}

.transactions li:first-child {
  animation: fade .6s -.15s ease-out 1;
}

.transactions li:first-child + li {
  animation: fade .6s -.1s ease-out 1;
}

.transactions li:first-child + li + li {
  animation: fade .6s -.05s ease-out 1;
}


@keyframes fade {
  0%, 33.3% {
    opacity: 0;
    transform: translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.transactions li + li:before {
  content: "";
  display: block;
  margin: .4em .5em;
  border-top: 1px solid #eee;
}

.empty {
  padding: 3em 0;
  text-align: center;
}

.transactions li.helper + li:before {
  content: "";
  display: none;
}

.helper {
  position: relative;
  color: #aa8941;
  border-radius: .5em;
  font-size: .9em;
  padding: .8em 1em;
  margin: 1.3em -.7em .5em;
  background: rgba(255, 174, 0, 0.07);
  display: flex;
  align-items: center;
}

.helper.arrow:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 42px;
  border-top: 15px solid rgba(255, 174, 0, 0.07);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.button {
  margin: .6em .4em .6em 1em;
  color: #aa8941 !important;
  border-color: #aa8941 !important
}
.newsletter {
  min-height: 100px;
  background: #dfdfdf;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em 0;
  align-items: center;
}

@media (max-width: 960px) {
  .newsletter {
    display: block;
    text-align: center;
  }
}

.newsletter h2 {
  margin: 0 .5em;
  max-width: unset;
}

.newsletter div {
  padding: .1em .5em;
  text-align: center;
}

.nobreak {
  display: inline-block;
}

.newsletter input {
  max-width: 152px;
  margin-bottom: -.5em;
}

.newsletter button {
  margin: .5em;
  min-width: 125px;
}
.refresh {
  position: fixed;
  top: 0px;
  height: 3px;
  left: 0;
  width: 100vw;
  z-index: 9998;
  background-color: rgb(246, 195, 88);
  transform-origin: center;
  transform: scaleX(0);
  opacity: 0;
  transition: .2s opacity linear;
}

.refresh.refreshing {
  opacity: 1 !important;
  width: 200vw;
  background-color: rgb(0, 137, 158, 0);
  background-image: linear-gradient(90deg, rgb(0, 137, 158, 0) 20%, rgb(246, 195, 88) 20.01%, rgb(246, 195, 88) 80%,  rgb(0, 137, 158, 0) 80.01%, rgb(0, 137, 158, 0) 100%);
  background-size: 20vw 100%;
  animation: move 3s linear infinite;
}

@keyframes move {
  0% {
    transform: scaleX(1) translateX(-100vw);
  }
  100% {
    transform: scaleX(1) translateX(-0vw);
  }
}